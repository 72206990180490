<template lang="html">
  <IxModuleRoot
    :module="module" persistent
    :module-path="['fieldRecordSystem']"
    :translation-namespaces="translationNamespaces"
    :setup="setup"
    route="/FieldRecordSystem"
  >
    <router-view />

    <TranslationControls />

    <template #loading="{loaded, error}">
      <FrsLoadingIndicator :loading="!loaded" :error="error" large>
        <span slot="error">Something went wrong during loading, please reload</span>
      </FrsLoadingIndicator>
    </template>
  </IxModuleRoot>
</template>

<script>
import {mapActions} from 'vuex'

import {reduceHeader} from 'src/coffee/helpers/layout'

import IxModuleRoot from '@components/IxModuleRoot'
import TranslationControls from '@components/translation/TranslationControls'
import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'

import SelfDestruct from '@mixins/SelfDestruct'
import PermissionMixin from '@mixins/PermissionMixin'

export default {
  components: {
    IxModuleRoot,
    TranslationControls,
    FrsLoadingIndicator
  },
  mixins: [
    SelfDestruct,
    PermissionMixin
  ],
  computed: {
    module () {
      return () => import('@store/modules/field-record-system')
    },
    translationNamespaces () {
      return [
        'Areas.ApplicationMaps.SR_ApplicationMaps',
        'Areas.FieldRecordSystem',
        'Areas.EntityManagement.SR_EntityManagement',
        'Areas.LanePlanning.SR_LanePlanning',
        'Areas.MaizeSowing.SR_MaizeSowing',
        'Areas.SimpleApplicationMaps',
        'Areas.VehicleFleet.SR_VehicleFleet',
        'Common.SR_Common',
        'Controls.Placeholders',
        'Enums.SR_Enums',
        'Messages',
        'Areas.DataTransfer.SR_DataTransfer',
        'ModelProperties.SR_ModelProperties',
        'core',
        'frs',
        'geoMeasurements',
        'download',
        'webgis',
        'bf',
        'actions',
        'SR_SimonModule',
        'osb',
        'masterData.crops.crop',
        'masterData.crops.usage',
        'masterData.crops.quality',
        'masterData.product_algorithm',
        'dataTransfer',
        'masterData',
        'cultivation.statistics'
      ]
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem', [
      'loadUserPermissions',
      'loadUserSettings',
      'reloadEntities',
      'preloadStaticData'
    ]),
    async setup () {
      await Promise.all([
        this.loadUserSettings(), // load harvest year before entities
        this.loadUserPermissions() // permissions are required for some following calls
      ])
      await Promise.all([
        this.reloadEntities(),
        this.preloadStaticData()
      ])
    }
  },
  created () {
    reduceHeader()
  }
}
</script>

<style lang="scss" scoped>
::v-deep .frs-root {
  flex: 1;
  display: flex;
  flex-direction: column;
}
</style>
