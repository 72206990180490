/*
  since all of the routes in the map management module occour both on field and on orgUnit,
  they are created by the below method and currently used in router.js to create subroutes of 'org/:orgUnitId' and 'field/:fieldId' paths.

  the general pattern for routes is the name of the route with an appended 'Org' or 'Field'.
  e.g. the mapManagement routes are mapManagementOrg and mapManagementField

  allowEntityChange: if true, the current route remains acitve if another entity is selected in the navigation

  mirrorRoute: if true, it is possible to switch to the 'opposite' route type, e.g. its possible to reach map management for fields if map management for orgUnit
  has been active before. therefore, the mirrored route gets automatically created by the MirrorRouteMixin

*/
export const generateMapManagementRoutes = (type) => {
  const children = [
    {
      path: '',
      name: `mapManagement${type}`,
      meta: {wide: true, mirrorRoute: true, allowEntityChange: true},
      component: () => import('@frs/components/map-management/MapManagement.vue')
    },
    {
      path: ':mapType/:mapId',
      name: `mapDetails${type}`,
      component: () => import('@frs/components/map-management/MapDetails.vue')
    },
    {
      path: 'zone-maps/:mapId/additional-values',
      name: `zoneMapAdditionalValues${type}`,
      component: () => import('@frs/components/map-management/details/ZoneMapAdditionalValues.vue')
    },
    {
      path: 'productSummary',
      name: `productSummary${type}`,
      meta: {wide: true},
      component: () => import('@frs/components/map-management/details/ApplicationMapProductSummary.vue')
    }
  ]

  return {
    path: 'maps',
    component: () => import('@frs/components/map-management/MapManagementModuleRoot'),
    children
  }
}
