import VueRouter from 'vue-router'
import {generateMapManagementRoutes} from './components/map-management/map-management-routes'
// import store from '@store'

export default new VueRouter({
  mode: 'history',
  base: '/FieldRecordSystem',
  routes: [
    {
      path: '/',
      name: 'root',
      component: () => import('./FieldRecordSystem')
    },
    {
      path: '/:year(\\d+)',
      component: () => import('./FieldRecordSystem'),
      props: route => ({year: parseInt(route.params.year)}),
      children: [
        {
          path: '',
          components: {
            default: () => import('./RightSidebar'),
            left: () => import('./LeftSidebar')
          },
          children: [
            {
              path: '',
              name: 'home',
              component: () => import('@frs/components/DefaultRightSidebar')
            }
          ]
        },
        {
          path: 'org/:orgUnitId',
          components: {
            default: () => import('./RightSidebar'),
            left: () => import('./LeftSidebar')
          },
          children: [
            {
              path: '',
              name: 'orgDashboard',
              component: () => import('@frs/components/orgunit/CompanyDashboard')
            },
            {
              path: 'newField',
              name: 'newField',
              component: () => import('@frs/components/field-details/editing/BasicFieldInfoForm')
            },
            {
              path: 'pendingZoneMapOrders',
              name: 'pendingZoneMapOrders',
              component: () => import('@frs/components/pending-zone-map-orders/PendingZoneMapOrdersModuleRoot'),
              meta: {wide: true}
            },
            generateMapManagementRoutes('Org'),
            {
              path: 'cultivations',
              component: () => import('@frs/components/cultivation-overview/CultivationModuleRoot'),
              children: [
                {
                  path: '',
                  name: 'cultivations',
                  component: () => import('@frs/components/cultivation-overview/summary/CultivationSummary'),
                  meta: {leftSidebar: true},
                  props: route => ({
                    year: parseInt(route.params.year),
                    orgUnitId: route.params.orgUnitId,
                    selectedFieldId: route.query.selectedFieldId
                  })
                },
                {
                  path: ':cultivationId',
                  name: 'details',
                  component: () => import('@frs/components/cultivation-overview/details/CultivationInfo'),
                  meta: {leftSidebar: true},
                  props: route => ({
                    year: parseInt(route.params.year),
                    orgUnitId: route.params.orgUnitId,
                    cultivationId: route.params.cultivationId
                  })
                }
              ]
            }, {
              path: 'products',
              component: () => import('@frs/components/products/ProductsModuleRoot'),
              children: [
                {
                  path: '',
                  name: 'product-management',
                  component: () => import('@frs/components/products/ProductManagement')
                },
                {
                  path: 'details/:productId?',
                  name: 'product-details',
                  component: () => import('@frs/components/products/details/ProductDetails'),
                  props: route => ({
                    productId: route.params.productId
                  })
                }
              ]
            }
          ]
        },
        {
          path: 'field/:fieldId',
          components: {
            default: () => import('./RightSidebar'),
            left: () => import('./LeftSidebar')
          },
          children: [
            {
              path: '',
              name: 'fieldDashboard',
              component: () => import('@frs/components/field-details/FieldDetailsPanel')
            },
            {
              path: 'info',
              name: 'basicFieldInfoForm',
              component: () => import('@frs/components/field-details/editing/BasicFieldInfoForm')
            },
            {
              path: 'soil',
              name: 'soilDetailsForm',
              component: () => import('@frs/components/field-details/editing/SoilDetailsForm')
            },
            {
              path: 'nutrientRequirements',
              name: 'nutrientRequirements',
              component: () => import('@frs/components/nutrient-requirements/NutrientRequirementsModuleRoot'),
              meta: {wide: true}
            },
            {
              path: 'mzk-plus',
              name: 'manualZoneMaps',
              component: () => import('@frs/components/zone-maps/ZoneMapsModuleRoot'),
              meta: {leftSidebar: true}
            },
            {
              path: 'auto-mzk',
              component: () => import('@frs/components/automatic-zone-maps/AutomaticZoneMapsModuleRoot'),
              children: [
                {
                  path: 'creating',
                  name: 'creatingAutomaticZoneMap',
                  component: () => import('@frs/components/automatic-zone-maps/PleaseWait'),
                  meta: {wide: true}
                },
                {
                  path: ':mode?',
                  name: 'automaticZoneMaps',
                  component: () => import('@frs/components/automatic-zone-maps/AutomaticZoneMaps'),
                  meta: {wide: true}
                }
              ]
            },
            {
              path: 'biomass-maps',
              name: 'biomassMaps',
              component: () => import('@frs/components/biomass-maps/BiomassMapsModuleRoot'),
              meta: {leftSidebar: true}
            },
            {
              path: 'simple-application-maps',
              name: 'simpleApplicationMaps',
              component: () => import('@frs/components/simple-application-maps/SimpleApplicationMaps')
            },
            {
              path: 'phbb',
              meta: {allowEntityChange: true},
              component: () => import('@frs/components/phbb/PhBbModuleRoot'),
              children: [
                {
                  path: '',
                  name: 'phbb',
                  component: () => import('@frs/components/phbb/PhBb')
                },
                {
                  path: 'interpolation',
                  name: 'interpolateSensorData',
                  component: () => import('@frs/components/phbb/interpolation/InterpolationForm')
                },
                {
                  path: 'calibrate-sensor-data',
                  name: 'calibrateSensorData',
                  component: () => import('@frs/components/phbb/calibration/CalibrationForm'),
                  meta: {wide: true}
                },
                {
                  path: 'cao-required-nutrient',
                  name: 'createCaORequiredNutrient',
                  component: () => import('@frs/components/phbb/create/cao-required-nutrient/RequiredNutrient')
                },
                {
                  path: 'cao-application',
                  name: 'createCaOApplication',
                  component: () => import('@frs/components/phbb/create/cao-application/Application')
                },
                {
                  path: 'lime-application',
                  name: 'createLimeApplication',
                  component: () => import('@frs/components/phbb/create/lime-application/LimeApplication')
                },
                {
                  path: 'order',
                  name: 'orderAdministration',
                  component: () => import('@frs/components/phbb/create/sampling-order/SamplingOrderAdministration'),
                  props: true,
                  meta: {wide: true}
                },
                {
                  path: 'order/create',
                  name: 'createPhBbOrder',
                  component: () => import('@frs/components/phbb/create/sampling-order/SamplingOrderCreation'),
                  meta: {wide: true}
                },
                {
                  path: 'probing-results',
                  name: 'probingResults',
                  meta: {wide: true},
                  component: () => import('@frs/components/phbb/probing-results/OrderResult')
                }
              ]
            },
            generateMapManagementRoutes('Field')
          ]
        },
        {
          path: 'tags',
          components: {
            default: () => import('./RightSidebar'),
            left: () => import('./LeftSidebar')
          },
          children: [
            {
              path: '',
              name: 'tagNavigationLandingPage',
              component: () => import('@frs/components/orgunit/TagGroupLandingPage')
            },
            {
              path: ':tag',
              name: 'tagDashboard',
              component: () => import('@frs/components/orgunit/TagGroupDashboard')
            },
            {
              path: ':tag/field/:fieldId',
              name: 'tagFieldDetails',
              component: () => import('@frs/components/field-details/FieldDetailsPanel')
            }
          ]
        }
      ]
    },
    {
      path: '*',
      redirect: '/'
    }
  ]
})
